import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box } from "@chakra-ui/layout";
import {
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import React, { useRef, useState, useEffect } from "react";
import { Quiz } from "../quiz";
import { AnswerKey } from "../answerKey";
import { FaPrint } from "react-icons/fa";
import { RxReset } from "react-icons/rx";
import { TbReload } from "react-icons/tb";
import { useReactToPrint } from "react-to-print";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { getQuiz } from "./getQuiz";
import Slide0 from "./slide0";
import Slide1 from "./slide1";
import Slide2 from "./slide2";
import Slide3 from "./slide3";
import PDFQuiz from "./renderedPdf";
import WizNav from "./wizNav";
const steps = [
  { label: "What education level are the students taking this quiz?" },
  { label: "What is the topic of the quiz?" },
  { label: "How many questions would you like?" },
  { label: "How difficult is the quiz?" },
  /* { label: "Additional Options" }, */
];

export const Wizard = ({ variant }) => {
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  const totalReset = () => {
    reset();
    setLevel("elementary");
    setTopic("magicians or unicorns");
    setNumQuestions(10);
    setDifficulty("easy");
    setQuiz(null);
    setLoading(false);
  };

  const isLastStep = activeStep === steps.length - 1;
  const hasCompletedAllSteps = activeStep === steps.length;

  const bg = useColorModeValue("gray.200", "gray.700");

  const [level, setLevel] = useState("elementary");
  const [topic, setTopic] = useState("");
  const [numQuestions, setNumQuestions] = useState(10);
  const [difficulty, setDifficulty] = useState("easy");
  const [quiz, setQuiz] = useState(null);
  const [loading, setLoading] = useState(false);

  const quizRef = useRef();
  const answerKeyRef = useRef();

  const handleQuizPrint = useReactToPrint({
    content: () => quizRef.current,
  });

  const handleAnswerKeyPrint = useReactToPrint({
    content: () => answerKeyRef.current,
  });

  useEffect(() => {
    //if (hasCompletedAllSteps)
    //  getQuiz(level, topic, numQuestions, difficulty, setQuiz, setLoading);
  }, [hasCompletedAllSteps]);

  return (
    <Box p={"25px"} fontFamily={"Roboto"}>
      <Flex flexDir="column" width="100%">
        <Box width={"100%"}>
          <Steps
            variant={variant}
            colorScheme="blue"
            responsive={true}
            activeStep={activeStep}
            fontFamily={"Roboto"}
          >
            {steps.map(({ label }, index) => (
              <Step label={label} key={label}>
                {
                  {
                    0: (
                      <Slide0
                        setLevel={setLevel}
                        level={level}
                        index={index}
                        prevStep={prevStep}
                        nextStep={nextStep}
                      />
                    ),
                    1: (
                      <Slide1
                        topic={topic}
                        setTopic={setTopic}
                        index={index}
                      />
                    ),
                    2: (
                      <Slide2
                        numQuestions={numQuestions}
                        setNumQuestions={setNumQuestions}
                        index={index}
                      />
                    ),
                    3: (
                      <Slide3
                        difficulty={difficulty}
                        setDifficulty={setDifficulty}
                        index={index}
                        prevStep={prevStep}
                        nextStep={nextStep}
                      />
                    ),
                    4: (
                      <Box mt={"50px"}>
                        <Center></Center>
                      </Box>
                    ),
                  }[index]
                }
                <WizNav hasCompletedAllSteps={hasCompletedAllSteps} reset={reset} activeStep={activeStep} prevStep={prevStep} nextStep={nextStep} isLastStep={isLastStep} />
              </Step>
            ))}
          </Steps>
        </Box>
        {hasCompletedAllSteps && (
          <>
            <Box sx={{ bg, my: 8, p: 8, rounded: "md" }}>
              <Heading fontSize={["md", "xl"]} textAlign={"center"}>
                Woohoo! All steps completed! 🎉
                {loading ? null : (
                  <>
                    <Box pt={"25px"}>
                      <Center>
                        <HStack gap={2} justify={"center"} wrap="wrap">
                          <Button
                            leftIcon={<TbReload />}
                            colorScheme={"green"}
                            onClick={() =>
                              getQuiz(
                                level,
                                topic,
                                numQuestions,
                                difficulty,
                                setQuiz,
                                setLoading
                              )
                            }
                          >
                            {quiz ? "Re-generate Quiz" : "Generate Quiz"}
                          </Button>
                          <Button
                            colorScheme={"red"}
                            leftIcon={<RxReset />}
                            onClick={() => totalReset()}
                          >
                            Make New Quiz
                          </Button>
                        </HStack>
                      </Center>
                    </Box>
                  </>
                )}
              </Heading>
            </Box>
            {loading ? (
              <Center>
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Center>
            ) : null}
            {quiz ? (
              <Box>
                <Center>
                  <Box>
                    <HStack>
                      <PDFDownloadLink
                        document={<PDFQuiz quizData={quiz} />}
                        fileName="quizgen_quiz.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            "Loading PDF..."
                          ) : (
                            <Button>Download PDF</Button>
                          )
                        }
                      </PDFDownloadLink>

                      <Button
                        onClick={() => handleQuizPrint()}
                        leftIcon={<FaPrint />}
                      >
                        Print Quiz
                      </Button>
                      <Button
                        onClick={() => handleAnswerKeyPrint()}
                        leftIcon={<FaPrint />}
                      >
                        Print Answer Key
                      </Button>
                    </HStack>
                  </Box>
                </Center>
              </Box>
            ) : null}
            <Box ref={quizRef}>
              <div>
                {quiz ? (
                  <>
                    <Quiz quizData={quiz} />{" "}
                  </>
                ) : null}
              </div>
            </Box>
            {quiz ? (
              <Box sx={{ bg, my: 8, p: 8, rounded: "md" }}>
                <Box ref={answerKeyRef} p={5}>
                  <Heading size="sm">Answer Key</Heading>{" "}
                  <AnswerKey quizData={quiz} />
                </Box>
              </Box>
            ) : null}
          </>
        )}
      </Flex>
    </Box>
  );
};

export default Wizard;
