import {
  Box,
  FormControl,
  FormLabel,
  FormHelperText,
  Slide,
  Center,
  Input,
} from "@chakra-ui/react";

import React from "react";

import { QuijenDropzone } from "./dropzone";

export default function Slide1({ topic, setTopic, index }) {
  return (
    <Slide
      in={(index === 1).toString()}
      out={(index !== 1).toString()}
      style={{ position: "relative" }}
      direction="right"
    >
      <Center>
        <Box mt={"50px"}>
          <FormControl>
            <FormLabel>
              <Center>Type a Topic</Center>
            </FormLabel>
            <Input
              onChange={(e) => setTopic(e.target.value)}
              value={topic}
              _placeholder={{ textAlign: "center" }}
              placeholder="e.g. Zebras, Napoleon, Photography of the 1980s"
              maxLength={100}
              fontSize={["10pt", "12pt"]}
            />
            <FormHelperText>
              <Center>
                You can be as detailed or as simplistic as you like.  If no topic is provided, magically create one.
              </Center>
            </FormHelperText>
          </FormControl>
        </Box>
      </Center>
      <Center>
        <Box mt={"50px"}>
          <FormControl>
            <FormLabel>
              <Center>(Optional) Upload Class Materials</Center>
            </FormLabel>
            <QuijenDropzone />
          </FormControl>
        </Box>
      </Center>
    </Slide>
  );
}
