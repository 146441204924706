import React, { useMemo, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Center,
  Button,
  FormHelperText,
  HStack,
  StackItem,
} from "@chakra-ui/react";
import { TiUploadOutline } from "react-icons/ti";
import { MdDeleteForever } from "react-icons/md";

const baseStyle = {
  position: "relative",
  height: "100px",
  color: "grey",
  lineHeight: "55px",
  verticalAlign: "middle",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "gray",
  borderStyle: "dashed",
  backgroundColor: "rgb(237, 242, 247)",
  outline: "none",
  width: "100%",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const focusedStyle = {
  borderColor: "#2196f3",
  cursor: "pointer",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export function QuijenDropzone(props) {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const binaryStr = reader.result;
          setFiles([...files, { contents: binaryStr, filename: file.name }]);
        };

        reader.readAsArrayBuffer(file);
      });
    },
    [files]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ onDrop, disabled: files.length === 3 });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const removeFIle = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Center>
          {files.length > 0 ? (
            <>
              <Box>
                {files.length} PDFs Uploaded.
                {files.length === 3 ? "  Maximum Reached." : ""}
              </Box>
            </>
          ) : (
            <HStack>
              <StackItem>
                <TiUploadOutline />
              </StackItem>
              <StackItem fontSize={["10pt", "12pt"]}>
                Drag and Drop or Click to Upload PDFs
              </StackItem>
            </HStack>
          )}
        </Center>
      </div>
      <Center>
        <HStack mt={"10px"}>
          {files.length > 0
            ? files.map((file, index) => {
                return (
                  <StackItem key={index}>
                    <Button
                      onClick={() => {
                        removeFIle(index);
                      }}
                      fontSize={"10pt"}
                      variant="outline"
                      colorScheme={"red"}
                      leftIcon={<MdDeleteForever />}
                    >
                      Remove '{file.filename}'
                    </Button>
                  </StackItem>
                );
              })
            : ""}
        </HStack>
      </Center>
      {files.length === 0 ? (
        <FormHelperText>
          <Center>
            Upload study guides, lesson plans, or other materials in PDF format. We will try
            to ensure your quiz will pertain to information you provide.
          </Center>
        </FormHelperText>
      ) : (
        ""
      )}
    </div>
  );
}
