import { Box, Slide, Center } from "@chakra-ui/react";
import StudentLevel from "../studentLevel";

export default function Slide0({ setLevel, level, index, nextStep, prevStep }) {
  return (
    <Slide
      in={(index === 0).toString()}
      out={(index !== 0).toString()}
      style={{ position: "relative" }}
      direction="right"
    >
      <Box mt={"50px"}>
        <Center>
          <StudentLevel
            setLevel={setLevel}
            level={level}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        </Center>
      </Box>
    </Slide>
  );
}
