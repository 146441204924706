import { Flex, Button, Box } from "@chakra-ui/react";

const WizNav = ({ hasCompletedAllSteps, reset, activeStep, prevStep, nextStep, isLastStep }) => {
    return ( <Flex width="100%" justify="center" gap={4} mt={"25px"}>
        {hasCompletedAllSteps ? (
          <Button size="sm" onClick={reset}>
            Reset
          </Button>
        ) : (
          <Box display={activeStep === 0 ? "none" : "block"}>
            <Button
              isDisabled={activeStep === 0}
              onClick={prevStep}
              size="sm"
              variant="ghost"
            >
              Prev
            </Button>
            <Button
              display={activeStep === 3 ? "none" : ""}
              size="sm"
              onClick={nextStep}
              disabled={activeStep === 0}
            >
              {isLastStep ? "Finish" : "Next"}
            </Button>
          </Box>
      )}
    </Flex>
  );
};

export default WizNav;
