import React from "react";
import { Box, Heading, } from "@chakra-ui/react";

export default function MultipleChoiceQ({question, index}) {
    return (
        <Box key={index} mb={"25px"}>
          <Heading size="md">{index + 1}. {question.question}</Heading>
          {question.answers.map((answer, answerIndex) => {
            let letter = "A";
            if (answerIndex === 0) letter = "A";
            else if (answerIndex === 1) letter = "B";
            else if (answerIndex === 2) letter = "C";
            else if (answerIndex === 3) letter = "D";
            else if (answerIndex === 4) letter = "E";

            return (
              <Box key={`question ${index} answer ${answerIndex}`}>
                {letter}. - {answer}
              </Box>
            );
          })}
        </Box>
      );
}