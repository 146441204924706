
import axios from "axios";

export function getQuiz(level, topic, numQuestions, difficulty, setQuiz, setLoading) {
    // setLoading(true);
    setQuiz(null);

    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + '/quiz/',
        {
          level,
          topic,
          numQuestions,
          difficulty,
        },
        {
          headers: {
            'Content-type':'application/json', 
            'Accept':'application/json'
          },
        }
      )
      .then((response) => {
        let data = response.data;

        let questions = data[0].questions;
        let answers = data[1].answers;

        console.log('questions', questions)
        console.log('answers', answers)

        setQuiz({ questions, answers });
        setLoading(false);
      });
  }