import * as React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "./App.css";
import { StepsTheme as Steps } from "chakra-ui-steps";
import Wizard from "./components/wizard";
import ThreeTierPricing from "./components/ui/pricing";
import Support from "./components/ui/support";
import Nav from "./components/nav";
// import { GoogleOAuthProvider } from "@react-oauth/google";
import "typeface-roboto";
import { ClerkProvider } from "@clerk/clerk-react";
import { Routes, Route, BrowserRouter, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const clerkKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const theme = extendTheme({
  fonts: {
    body: "Roboto",
    heading: "Roboto",
    mono: "Roboto",
  },
  components: {
    Steps,
    Link: {
      baseStyle: {
        textDecoration: "underline",
        color: "blue.500",
        _hover: {
          textDecoration: "none",
        },
      },
    },
    Radio: {
      baseStyle: {
        size: "lg",
        control: {
          _checked: {
            bg: "blue.500",
            borderColor: "blue.500",
          },
        },
      },
    },
  },
});

function App() {
  if (module.hot && process.env.NODE_ENV !== "production") {
    module.hot.accept();
  }

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('paymentSuccess');

  if(myParam === "true") {
    toast.success('Subscription successful', {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  return (
    <BrowserRouter>
      {/* <GoogleOAuthProvider clientId={googleClientId}> */}
      <ClerkProvider publishableKey={clerkKey} afterSignOutUrl="/">
        <ChakraProvider theme={theme}>
        <ToastContainer
        autoClose={3000}
        position="top-center"
        hideProgressBar={true}
        closeOnClick
      />
          <Nav />
          <Routes>
            <Route path="/" element={<Wizard variant={"simple"} />} />
            <Route path="/pricing" element={<ThreeTierPricing />} />
            <Route path="/support" element={<Support />} />
          </Routes>
        </ChakraProvider>
      </ClerkProvider>
    </BrowserRouter>
  );
}

export default App;
