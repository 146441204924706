import React from "react";
import { Page, Text, View, Document, StyleSheet, Font } from "@react-pdf/renderer";
import lodash from "lodash";

Font.register({
  family: 'Lato',
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: 'Lato Bold',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Lato',
    flexDirection: "row",
  },
  question: {
    fontFamily: 'Lato Bold',
    color: "black",
    fontSize: 14,
    fontWeight: "bold",
  },
  answer: {
    color: "black",
    fontSize: 12,
  },
  body: {
    margin: 10,
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

export default function PDFQuiz({ quizData }) {
  const pages = lodash.chunk(quizData?.questions, 5);

  return (
    <Document style={styles.body}>
      {pages.map((page, index) => (
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            {page.map((question, index) => {
              return (
                <>
                  <Text style={styles.question} key={index}>
                    {question.question}
                  </Text>
                  <View style={styles.section}>
                    {question.answers.map((answer, answerIndex) => {
                      let letter = "A";

                      if (answerIndex === 0) letter = "A";
                      else if (answerIndex === 1) letter = "B";
                      else if (answerIndex === 2) letter = "C";
                      else if (answerIndex === 3) letter = "D";
                      else if (answerIndex === 4) letter = "E";

                      return (
                        <Text
                          style={styles.answer}
                          key={"answer " + answerIndex}
                        >
                          {letter}. - {answer}
                        </Text>
                      );
                    })}
                  </View>
                </>
              );
            })}
          </View>
        </Page>
      ))}
    </Document>
  );
}
