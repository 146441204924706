"use client";

import {
  Box,
  Center,
  StackItem,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Button from "../button";
import { useUser } from "@clerk/clerk-react";
import { FaCheckCircle } from "react-icons/fa";
import { GrSubtractCircle } from "react-icons/gr";
import { MdConstruction } from "react-icons/md";
import { SignedIn, SignedOut, SignInButton } from "@clerk/clerk-react";

function PriceWrapper(props) {
  const { children } = props;

  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

export default function ThreeTierPricing() {
  const clerkUser = useUser().user;

  const user = {
    id: clerkUser?.id,
    email: clerkUser?.primaryEmailAddress.emailAddress,
    name: clerkUser?.fullName,
  };

  return (
    <Box py={12}>
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          Get Your Quiz On
        </Heading>
        <Text fontSize="lg" color={"gray.500"}>
          Upgrade for Unlimited Quizzes
        </Text>
      </VStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Free
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                0
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={GrSubtractCircle} color="red.500" />
                10 Quiz Generations
              </ListItem>
              <ListItem>
                <ListIcon as={GrSubtractCircle} color="red.500" />
                Maximum of 10 Questions
              </ListItem>
              <ListItem>
                <ListIcon as={GrSubtractCircle} color="red.500" />
                Cannot Save, Edit, or Send Quizzes
              </ListItem>
            </List>
          </VStack>
        </PriceWrapper>

        <PriceWrapper>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: "translate(-50%)" }}
            >
              <Text
                textTransform="uppercase"
                bg={"green.400"}
                px={3}
                py={1}
                color={"white"}
                fontSize="sm"
                fontWeight="600"
                rounded="xl"
              >
                Most Popular
              </Text>
            </Box>
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                Monthly
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  6
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /month
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Unlimited Quiz Generations
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Up to 50 Questions
                </ListItem>
                <ListItem>
                  <ListIcon as={MdConstruction} color="yellow.500" />
                  Save, Edit, and Send Quizzes
                </ListItem>
                <ListItem>
                  <ListIcon as={MdConstruction} color="yellow.500" />
                  Multiple Choice and True/False Question Types
                </ListItem>
                <ListItem>
                  <ListIcon as={MdConstruction} color="yellow.500" />
                  Save Student Grades and View Analytics
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <SignedIn>
                  <Link to={process.env.REACT_APP_STRIPE_MONTHLY_BUY_LINK + user.email + '&client_reference_id=' + user.id}>
                    <Button w="full">Subscribe</Button>
                  </Link>
                </SignedIn>
                <SignedOut>
                  <SignInButton>
                    <Button w={"full"}>Get Started</Button>
                  </SignInButton>
                </SignedOut>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Annual
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                58
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /year
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />A 20% Discount
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                You will be notified before renewal
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <SignedIn>
                <Link to={process.env.REACT_APP_STRIPE_ANNUAL_BUY_LINK + user.email + '&client_reference_id=' + user.id}>
                  <Button w="full">Subscribe</Button>
                </Link>
              </SignedIn>
              <SignedOut>
                <SignInButton>
                  <Button w={"full"}>Get Started</Button>
                </SignInButton>
              </SignedOut>
            </Box>
          </VStack>
        </PriceWrapper>
      </Stack>
      <Center>
        <HStack>
          <StackItem color="yellow.500">
            <MdConstruction />
          </StackItem>
          <Text>Coming Soon</Text>
        </HStack>
      </Center>
    </Box>
  );
}
