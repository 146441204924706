import { Button } from "@chakra-ui/react";

export default function QuijenButton(props) {
  return (
    <Button
      bg="blue.500"
      color="white"
      _hover={{
        transform: "scale3d(1.05, 1.05, 1)",
        backgroundColor: "green.400",
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
}
