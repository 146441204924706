import React from "react";
import { Box } from "@chakra-ui/react";

import MultipleChoiceQ from './multipleChoiceQ';

export const Quiz = (quizData) => {
  console.log(quizData);
  const quiz = quizData.quizData;

  return (
    <>
      <Box p={"25px"} id="quizOutput">
        {quiz?.questions?.map((question, index) => {
         return <MultipleChoiceQ question={question} index={index} />
        })}
      </Box>
    </>
  );
};
