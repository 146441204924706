import { Box } from "@chakra-ui/react";

export const AnswerKey = (quizData) => {
  const quiz = quizData.quizData;
  
  return (
    <Box p={"25px"} id="answerKeyOutput">
      {quiz?.answers?.map((answer, index) => {

        let letter = "A";
        if (answer.index === 0) letter = "A";
        else if (answer.index === 1) letter = "B";
        else if (answer.index === 2) letter = "C";
        else if (answer.index === 3) letter = "D";
        else if (answer.index === 4) letter = "E";
        
        return (
          <Box key={index}>
            {index + 1}. {letter}. - {answer.value}
          </Box>
        );
      })}
    </Box>
  );
};
