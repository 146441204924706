import {
  Box,
  Center,
  HStack,
  Slide,
} from "@chakra-ui/react";
import Button from '../button'

export default function Slide3({ difficulty, setDifficulty, index, prevStep, nextStep }) {
  const handleClick = (difficulty) => {
    setDifficulty(difficulty)
    nextStep()
  };

  return (
    <Slide
      in={(index === 3).toString()}
      out={(index !== 3).toString()}
      style={{ position: "relative" }}
      direction="right"
    >
      <Box mt={"50px"}>
        <Center>
          <HStack>
            <Button onClick={() => handleClick("easy")}>Easy</Button>
            <Button onClick={() => handleClick("medium")}>Medium</Button>
            <Button onClick={() => handleClick("hard")}>Hard</Button>
          </HStack>
        </Center>
        {/*
        <Center>
          <RadioGroup onChange={setDifficulty} value={difficulty}>
            <Stack>
              <Radio value="easy">Easy</Radio>
              <Radio value="medium">Medium</Radio>
              <Radio value="hard">Hard</Radio>
            </Stack>
          </RadioGroup>
        </Center>
        */}
      </Box>
    </Slide>
  );
}
