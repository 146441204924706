import {
  Box,
  Center,
  Slide,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from "@chakra-ui/react";

export default function Slide2({ numQuestions, setNumQuestions, index }) {
  return (
    <Slide
      in={(index === 2).toString()}
      out={(index !== 2).toString()}
      style={{ position: "relative" }}
      direction="right"
    >
      <Box width={"100%"} pl={"5%"} pr={"5%"}>
        <Box pt={"55px"} pb={"55px"} fontSize={"24pt"}>
          <Center>{numQuestions} questions</Center>
        </Box>
        <Slider
          onChange={(val) => setNumQuestions(val)}
          defaultValue={10}
          max={50}
        >
          <SliderMark value={0}>0</SliderMark>
          <SliderMark value={10}>10</SliderMark>
          <SliderMark value={20}>20</SliderMark>
          <SliderMark value={30}>30</SliderMark>
          <SliderMark value={40}>40</SliderMark>
          <SliderMark value={50}>50</SliderMark>
          <SliderMark
            value={numQuestions}
            textAlign="center"
            bg="blue.500"
            color="white"
          ></SliderMark>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </Box>
    </Slide>
  );
}
